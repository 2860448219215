import { useEffect } from 'react';
import { FiltersEnums, PriceValue } from '@orascom/api-interfaces';
import PropertyCard from '../common/property-card/property-card';
import { NavyButton } from '../common/button/button';
import { useTranslation } from 'react-i18next';
import {
  GoldButton,
  SkeletonUnit,
  UnitsFilters,
} from '@orascom/common-components';
import CompareButton from '../common/compare-button/compare-button';
import styles from './units-listing.module.scss';
import { Filter as FilterApi } from '../../utils/filter-utils';
import {
  useCurrencyContext,
  useCurrencyConverter,
  useDisclosure,
  usePriceSlider,
  useUnitFilters,
  useUnits,
} from '@orascom/utils';
import { Unit as UnitApi } from '../../utils/unit.utils';
import { useSearchParams, useParams, Link } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import arrowIcon from '../../assets/icons/right-nav-arrow.svg';
import TalkToSalesModal from '../common/talk-to-sales-modal/talk-to-sales-modal';

export interface UnitsListingProps {
  similarUnits?: boolean;
}

export function UnitsListing(props: Readonly<UnitsListingProps>) {
  const { t } = useTranslation();
  const { selectedCurrency, convertPrice } = useCurrencyConverter();
  const [searchParams] = useSearchParams();
  const params: string[] = [];
  searchParams.forEach((value) => {
    params.push(value);
  });
  const { unitId } = useParams();

  const unitsData = useUnits({
    getUnits: UnitApi.getUnits,
    selectedCurrency,
    convertPrice,
    similarUnits: props.similarUnits,
  });

  const { units, loadMoreUnits, paginationData, hasMoreUnits } = unitsData;
  const { isLoadingUnits, isLoadingMoreUnits } = unitsData;

  const { filters, isLoading: isLoadingFilters } = useUnitFilters({
    getUnitFilters: FilterApi.getUnitFilters,
  });

  const {
    isLoading: isLoadingCurrency,
    setActiveCurrency,
    setDisableCurrencySetting,
  } = useCurrencyContext();

  const { priceSlider, setPriceSlider } = usePriceSlider(filters);

  function setPriceValue(priceValue: PriceValue) {
    setPriceSlider({
      ...priceSlider,
      price: priceValue,
    });
  }

  const destinationParam = searchParams.get(FiltersEnums.DESTINATIONS);

  useEffect(() => {
    const isElGouna = destinationParam === 'el-gouna';

    if (isElGouna) {
      setActiveCurrency('USD');
      setDisableCurrencySetting({
        disabled: true,
        info: t(`elGounaCurrencyDisclaimer`),
      });
    }

    return () => {
      if (isElGouna) {
        setDisableCurrencySetting({
          disabled: false,
          info: '',
        });
      }
    };
  }, [destinationParam]);

  const NoUnitsMessage = () => {
    const { onOpen, isOpen, onClose } = useDisclosure();
    return (
      <div className={`${styles['no-results']} container`}>
        <h4>{t('noUnitsResults')}</h4>
        <p>{t('noUnitsResultsSubtitle')}</p>
        <p>{t('noUnitsResultsAction')}</p>
        <GoldButton onClick={onOpen}>{t('notifyMe')}</GoldButton>

        <TalkToSalesModal show={isOpen} onClose={onClose} />
      </div>
    );
  };

  const shouldShowSkeleton = isLoadingUnits || isLoadingMoreUnits;

  const LoadMoreUnitsButton = () => (
    <div className={styles['units__button']}>
      <NavyButton onClick={loadMoreUnits} disabled={shouldShowSkeleton}>
        {t('loadMore')}
      </NavyButton>
    </div>
  );

  const shouldShowUnits = Boolean(units.length) && !isLoadingUnits;
  const isSimilarUnitsPage = props.similarUnits ? true : false;
  const title = isSimilarUnitsPage ? t('allSimilarUnits') : t('availableUnits');
  return (
    <div className={styles['units']}>
      <CompareButton />

      <div className={`${styles['units__headers']} container`}>
        {isSimilarUnitsPage && (
          <Link
            to={ROUTES['UnitDetails'].getPath?.(unitId)}
            className={styles['back-anchor']}
          >
            <img
              className={styles['arrow-right']}
              src={arrowIcon}
              alt=""
              role="presentation"
            />
            <span className="anchor">{t('backToUnitDetails')}</span>
          </Link>
        )}
        <h2 className="orascom__sub-title">{title}</h2>
        {!isSimilarUnitsPage && (
          <div className={styles['units__headers__filters']}>
            <UnitsFilters
              portal="shopper"
              unitFilters={filters}
              isLoadingFilters={isLoadingFilters}
              showResale={true}
              priceSlider={priceSlider}
              setPriceValue={setPriceValue}
              isLoadingCurrency={isLoadingCurrency}
              showSortBy
            />
          </div>
        )}
      </div>

      {shouldShowUnits && (
        <>
          <div className={styles['units__wrapper']}>
            {units.map((unit) => (
              <PropertyCard key={unit.id} unit={unit} />
            ))}
          </div>

          {!isLoadingMoreUnits && (
            <div className={styles['units__count']}>
              <hr />
              <span>
                {t('showing')} {units?.length ?? 0} {t('unitsOutOf')}{' '}
                {paginationData?.total ?? 0}
              </span>
            </div>
          )}
        </>
      )}

      {shouldShowSkeleton && (
        <div className={styles['units__wrapper']}>
          {Array.from({ length: 12 }, (_, i) => (
            <SkeletonUnit key={i} />
          ))}
        </div>
      )}

      {hasMoreUnits && <LoadMoreUnitsButton />}

      {units.length === 0 && !shouldShowSkeleton && <NoUnitsMessage />}
    </div>
  );
}

export default UnitsListing;
