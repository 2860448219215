import spinner from './spinner.svg';
import styles from './spinner.module.scss';

export interface SpinnerProps {
  size?: 'sm' | 'md';
}
export function Spinner({ size = 'md' }: Readonly<SpinnerProps>) {
  return (
    <div className={`${styles['spinner']} ${styles[size]}`}>
      <img src={spinner} alt="" role="presentation" />
    </div>
  );
}

export default Spinner;
